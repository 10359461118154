import React from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../layouts/Landing';
import Privacy from '../routes/Privacy';

interface PrivacyItProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                tagline: string;
            };
        };
    };
}

const privacyIt = ({
    data: {
        location,
        site: {
            siteMetadata: { name },
        },
    },
}: PrivacyItProps) => {
    return (
        <LandingLayout lang="it" location={location}>
            <Privacy lang="it" />
        </LandingLayout>
    );
};

export const privacyItPageQuery = graphql`
    query privacyItPageQuery {
        site {
            siteMetadata {
                name
                tagline
            }
        }
    }
`;

export default privacyIt;
